
import React from "react"

const Sep52020 = () => {
  return (
    <div className="shadow-md m-0 sm:m-3 p-3 w-full">
      <p className="text-xs">05. September 2020</p>
      <p>
        Guten Morgen
        <br />
        Heute gibt es einen Beitrag zum Thema {" "}
        <a href="/studieren-mit-kind" className="text-blue-700">
          studieren mit Kind
        </a>. <br />
        habt ein schönes Wochenende !
      </p>
    </div>
  )
}

export default Sep52020
