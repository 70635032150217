import React from "react"

import Layout from "../components/layout"
import FirstTimeline from "../timeline/firstTimeline"
import Sep52020 from "../timeline/sep52020"

class Timeline extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = 'Datenschutzerklärung'

    return (
      <Layout location={this.props.location} title={siteTitle}>
          <h1 className="text-2xl">Timeline</h1>
          <div className="flex flex-col">
              <Sep52020></Sep52020>
              <FirstTimeline></FirstTimeline>
          </div>

      </Layout>
    )
  }
}
export default Timeline