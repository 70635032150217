
import React from "react"

const FirstTimeline = () => {
  return (
    <div className="shadow-md m-3 p-3 w-full">
      <p className="text-xs">04. September 2020</p>
      <p>
        Guten Morgen
        <br />
        Heute gibt es{" "}
        <a href="/wer-bin-ich" className="text-blue-700">
          eine kleine Vorstellung meinerseits
        </a>
        , doch dabei soll es nicht bleiben. Morgen wird der erste richtige
        Inhalt geboten. Also besucht mich wieder.
      </p>
    </div>
  )
}

export default FirstTimeline
